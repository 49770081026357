import React from 'react'
import DesignSystem from '../../components/DesignSystem'

import Islands from './index'

const Molokai = () => {
  return (
    <Islands activeIsland="molokai">
      <DesignSystem.H1>Molokai (Moloka'i)</DesignSystem.H1>
      <DesignSystem.P>blurb</DesignSystem.P>
    </Islands>
  )
}

export default Molokai
